import React from 'react';
import Slider from 'react-slick';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from './Layout';

export default ({ data }) => (
  <Layout seo={data.datoCmsGuide.seoMetaTags}>
    <article className="sheet">
      <div className="sheet__inner">
        <h1 className="sheet__title">{data.datoCmsGuide.title}</h1>
        <p className="sheet__lead">{data.datoCmsGuide.excerpt}</p>
        <div className="sheet__slider">
          <div className="sheet__gallery">
            {data.datoCmsGuide.coverImage ? (
              <Img fluid={data.datoCmsGuide.coverImage.fluid} />
            ) : (
              <img
                src={data.datoCmsGuide.linkCoverImage}
                alt="Cover scp"
              ></img>
            )}
          </div>
        </div>
        <div
          className="sheet__body"
          dangerouslySetInnerHTML={{
            __html:
              data.datoCmsGuide.descriptionNode.childMarkdownRemark.html,
          }}
        />
        {(data.datoCmsGuide.gallery || data.datoCmsGuide.linkGallery) && (
          <Slider infinite={true} slidesToShow={1} arrows>
            {data.datoCmsGuide.gallery.length &&
              data.datoCmsGuide.gallery.map(({ fluid }) => (
                <img
                  alt={data.datoCmsGuide.title}
                  key={fluid.src}
                  src={fluid.src}
                />
              ))}
            {data.datoCmsGuide.linkGallery &&
              JSON.parse(data.datoCmsGuide.linkGallery).map(({ link }) => (
                <img alt={data.datoCmsGuide.title} key={link} src={link} />
              ))}
          </Slider>
        )}
      </div>
    </article>
  </Layout>
);

export const query = graphql`
  query SingleGuideQuery($slug: String!, $locale: String!) {
    datoCmsGuide(slug: { eq: $slug }, locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      gallery {
        fluid(maxWidth: 200, imgixParams: { fm: "jpg", auto: "compress" }) {
          src
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      linkCoverImage
      linkGallery
      coverImage {
        url
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;
